import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Clue10 = () => (
  <Layout>
    <Header /> 

    <header className="masthead_10">
    <br></br>
    <br></br>
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Clue #10</h1>
          <h2 className="font-weight-bold mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            PlaceHolder PlaceHolder 
            <br></br>
            PlaceHolder PlaceHolder
            <br></br>
            <br></br>
            PlaceHolder PlaceHolder
            <br></br>
            PlaceHolder PlaceHolder
            <br></br>
            <br></br>
            PlaceHolder PlaceHolder
            <br></br>
            PlaceHolder PlaceHolder
            <br></br>
            <br></br>
            PlaceHolder PlaceHolder
            <br></br>
            PlaceHolder PlaceHolder
          </h2>
          <br></br>
          <br></br>
          {/* <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              Hint
            </a>
          </Scroll> */}
            <a href="/hint10" className="btn btn-primary">
              Hint
            </a>           
        </div>
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="font-weight-bold text-white mb-4"></h2>
            <p className="text-white-50">
              {/* This is a hint for:  LAPTOP */}
            </p>
          </div>
        </div>
        <img src={ipad} className="img-fluid" alt="" />
      </div>
    </section>

    {/* <Subscribe /> */}

    {/* <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Clue10;
